
export default () => {
  const contentBlocks = document.querySelectorAll('.js-function-integrations__content-block')
  const integrationsSection = document.querySelector('#function-integrations')

  const media = window.matchMedia('screen and (max-width: 767px)')

  if (contentBlocks.length < 1 || integrationsSection === null) {
    return false
  }

  const run = async () => {
    try {
      const [{gsap}] = await Promise.all([
        import( /* @vite-ignore */'../gsapImport')
      ])

      const tween = gsap.to(contentBlocks, {
        duration: 20,
        x: (contentBlocks[0].clientWidth + (media.matches ? 20 : 0)) * -1,
        ease: 'none',
        repeat: -1,
        paused: true
      })

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            tween.play()
          } else {
            tween.pause()
          }
        })
      })

      observer.observe(integrationsSection)
    } catch (err) {
      /* eslint-disable */
      console.log(err.message)
      /* eslint-enable */
    }
  }

  run()
}
