import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

export default () => {
  gsap.registerPlugin(ScrollToPlugin)

  const textLinks = document.querySelectorAll('body a')
  const anchorTextLinks = [...textLinks].filter((link) => {
    return link.href.indexOf('#') !== -1
  })

  const getSamePageAnchor = (link) => {
    if (
      link.protocol !== window.location.protocol ||
      link.host !== window.location.host ||
      link.pathname !== window.location.pathname ||
      link.search !== window.location.search
    ) {
      return false
    }

    return link.hash
  }

  // Scroll to a given hash, preventing the event given if there is one
  const scrollToHash = (hash, e) => {
    const elem = hash ? document.getElementById(hash.replace('#', '')) : false
    if (elem) {
      if (e) e.preventDefault()
      gsap.to(window, {
        scrollTo: { y: elem, offsetY: 80, ease: 'linear' },
        onComplete: () => {
          window.history.pushState(null, null, `${hash}`)
        }
      })
    }
  }

  // If a link's href is within the current page, scroll to it instead
  anchorTextLinks.forEach((a) => {
    a.addEventListener('click', (e) => {
      scrollToHash(getSamePageAnchor(a), e)
    })
  })

  // Scroll to the element in the URL's hash on load
  scrollToHash(window.location.hash)
}
