
export default () => {

    const sideBar = document.getElementById('js-post-sidebar')
    const header = document.getElementById('js-post-header')

    if (sideBar === null || header === null) {
        return false
    }

  const run = async () => {
    try {
      const [{gsap, ScrollTrigger, ScrollToPlugin}] = await Promise.all([
        import( /* @vite-ignore */'../gsapImport')
      ])

      gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

      const plainBody = document.querySelector('#js-plain-content')
      if (plainBody !== null) {
        const sideBarList = sideBar.querySelector('ul')
        const plainBodyHeadings = plainBody.querySelectorAll('h2')
        plainBodyHeadings.forEach((el, i) => {
          el.classList.add('js-pillar-section')
          el.id = 'block-' + i
          const li = document.createElement('li')
          const button = document.createElement('button')
          li.classList.add('post-sidebar__item')
          button.classList.add('post-sidebar__link')
          button.setAttribute('data-target', 'block-' + i)
          button.innerText = el.textContent
          li.appendChild(button)
          sideBarList.appendChild(li)
        })
      }
      const navButtons = document.querySelectorAll('#js-post-sidebar button')
      const postSections = document.querySelectorAll('.js-pillar-section')

      const changeLink = (activeSectionId) => {
        navButtons.forEach((el) => {
          if (el.dataset.target === activeSectionId) {
            el.classList.add('is-active')
          } else {
            el.classList.remove('is-active')
          }
        })
      }

      ScrollTrigger.saveStyles('#js-post-sidebar, #js-post-body')
      ScrollTrigger.matchMedia({
        // desktop
        '(min-width: 1248px)': () => {
          // eslint-disable-next-line no-unused-vars
          let tl = gsap.timeline({
            scrollTrigger: {
              trigger: '#js-post-body',
              start: `top top+=${header.offsetHeight + 160}`,
              end: `bottom top+=${sideBar.offsetHeight + 180}`,
              pin: '#js-post-sidebar'
            }
          })

          navButtons.forEach((btn) => {
            btn.addEventListener(
                'click',
                (e) => {
                  gsap.to(window, {
                    scrollTo: {
                      y: '#' + e.target.dataset.target,
                      offsetY: 120,
                      ease: 'linear'
                    }
                  })
                },
                false
            )
          })

          // eslint-disable-next-line no-unused-vars
          postSections.forEach((section, i) => {
            ScrollTrigger.create({
              trigger: section,
              start: 'top top+=150px',
              onEnter: () => {
                if (section.id) {
                  changeLink(section.id)
                }
              },
              scrub: true
              // markers: true,
            })

            ScrollTrigger.create({
              trigger: section,
              end: 'bottom top+=200px',
              onEnterBack: () => {
                if (section.id) {
                  changeLink(section.id)
                }
              },
              scrub: true
              // markers: true,
            })
          })
        }
      })
    } catch (err) {
      /* eslint-disable */
      console.log(err.message)
      /* eslint-enable */
    }
  }

  run()
}
