export default () => {

  const list = document.querySelector('#function-detail-features')
  const nav = document.querySelector('#js-functions-overview-nav-product')

  if (nav === null || list === null) {
    return false
  }

  const run = async () => {
    try {
      const [{gsap, ScrollTrigger} ] = await Promise.all([
        import( /* @vite-ignore */'../gsapImport')
      ])

      gsap.registerPlugin(ScrollTrigger)

      ScrollTrigger.create({
        trigger: list,
        start: 'top -1',
        end: 'bottom bottom',
        toggleClass: { className: 'is-active', targets: nav }
      })
    } catch (err) {
      /* eslint-disable */
      console.log(err.message)
      /* eslint-enable */
    }
  }

  run()
}
