import slidingLogos from './_partials/functionDetail/slidingLogos'
// import progressBar from './_partials/redesign/pillar/progressBar'
import stickySidebar from './_partials/pillar/stickySidebar'
import stickySubNav from './_partials/functionDetail/stickySubNav'
import functionsHomeStickySubNav from './_partials/functions/functionsHomeStickySubNav'
import animateAnchorTextLinks from './_partials/functions/animateAnchorTextLinks'

const init = () => {
  slidingLogos()
  // progressBar()
  stickySidebar()
  stickySubNav()
  functionsHomeStickySubNav()
  animateAnchorTextLinks()
}

document.addEventListener('DOMContentLoaded', init, false)
document.addEventListener('htmx:afterRequest', init, false)

